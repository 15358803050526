import React from 'react'
import { useEffect } from 'react'; 
import LazyLoad from 'react-lazyload';
import { useLocation, Link, NavLink } from 'react-router-dom'
const Header = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
     <>
     <div className="logo-sec">
       <div className="container-fluid">
         <div className="row align-items-center">
           <div className="col-xl-4 col-lg-4 col-md-8 col-12"><Link to="/"> 
           <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/logo.png" className="img-fluid" alt="St. Xaviers Bathinda" /></LazyLoad>
           </Link> </div>
          
           <div className="col-xl-3 col-lg-2 col-md-4 col-12 kids-link">
             <div className="col">
               <div className="card">
                 <div className="backgroundEffect"></div>
                 <Link to="../JuniorHome" className="arrow"> 
                 <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/kids.png" className="card-img-top" alt="St. Xaviers Bathinda"/></LazyLoad>
                 <div className="card-body">
                   <h5>Kindergarten</h5>
                 </div>
                 </Link> </div>
             </div>
           </div>
           <div className="col-xl-3 col-lg-4 col-md-6 col-12">
             <ul className="list-inline">
               <li className="list-inline-item"> <i className="bi bi-phone"></i>0164 2211800, 0164 2240260</li>
               <div className="clearfix"></div>
               <li className="list-inline-item"> <i className="bi bi-envelope"></i> <Link to="mailto:xavierbathinda@gmail.com">xavierbathinda@gmail.com</Link></li>
             </ul>
             <div className="clearfix"></div>
             <ul className="list-inline social">
               <li className="list-inline-item"><Link to="https://www.facebook.com/profile.php?id=100074570446034" target="_blank"><LazyLoad><img alt="Facebook" title="Facebook" src="https://webapi.entab.info/api/image/SXSBPB/public/Images/facebook.png" className="img-fluid"/></LazyLoad></Link></li>
               <li className="list-inline-item"><Link to="https://twitter.com/xavierbathinda" target="_blank"><LazyLoad><img alt="Twitter" title="Twitter" src="https://webapi.entab.info/api/image/SXSBPB/public/Images/twitter.png" className="img-fluid"/></LazyLoad></Link></li>
               <li className="list-inline-item"><Link to="https://www.instagram.com/xavierbti/?hl=en" target="_blank"><LazyLoad><img alt="Instagram"  title="Instagram" src="https://webapi.entab.info/api/image/SXSBPB/public/Images/instagram.png" className="img-fluid"/></LazyLoad></Link></li>
               <li className="list-inline-item"><Link to="https://www.youtube.com/channel/UCgpIWMNZuHwptXrfehEaq6g" target="_blank"><LazyLoad><img title="Youtube" alt="Youtube" src="https://webapi.entab.info/api/image/SXSBPB/public/Images/youtube.png" className="img-fluid"/></LazyLoad></Link></li>
               <li className="list-inline-item"><Link target="_blank" to="https://wa.me/+916284746980"><LazyLoad><img alt="WhatsApp" title="WhatsApp" src="https://webapi.entab.info/api/image/SXSBPB/public/Images/whatsapp.png" className="img-fluid"/></LazyLoad></Link></li>
             </ul>
            </div>    
           <div className="col-xl-2 col-lg-2 col-md-6 col-12 mascot">
                 <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/mascot.png" className="card-img-top" alt="St. Xaviers Bathinda"/></LazyLoad>
           </div>
         </div>
       </div>
     </div>
     
<div className="menu-sec span3 wow bounceInDown center" data-wow-delay="1s">
<nav className="navbar navbar-expand-lg navbar-dark">
  <div className="container-fluid">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"  aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
    <div className="collapse navbar-collapse nav-fill justify-content-center" id="main_nav">
      <ul className="navbar-nav">
        <li className="nav-item dropdown"> <a className="nav-link dropdown-toggle" id="school" to="#" data-bs-toggle="dropdown"> <i className="bi bi-building"></i> About Us </a>
          <ul className="dropdown-menu">
             <li><NavLink  className="dropdown-item" id="school1" to="/SocietyPilar">Society Of Pilar </NavLink></li>
             <li><NavLink  className="dropdown-item" id="school2" to="/AboutSchool">About School </NavLink></li>
             <li><NavLink  className="dropdown-item" id="school11" to="/Vision">Vision </NavLink></li>
             <li><NavLink  className="dropdown-item" id="school12" to="/Mission">Mission </NavLink></li>
             <li><NavLink  className="dropdown-item" id="school3" to="/Goals">Goals </NavLink></li>
             <li><NavLink  className="dropdown-item" id="school4" to="/OurPatron">Our Patron </NavLink></li>
             <li><NavLink  className="dropdown-item" id="school5" to="/PrayerHymns">Prayer &amp; Hymns </NavLink></li>
             <li><NavLink  className="dropdown-item" id="school10" to="/Faculty">Faculty </NavLink></li>
             <li><NavLink  className="dropdown-item" id="school8" to="/OurStrength">Our Strength </NavLink></li>
             <li><NavLink  className="dropdown-item" id="school6" to="/Infrastructure">Infrastructure </NavLink></li>
          </ul>
        </li>
        <li className="nav-item dropdown"> <a className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown" id="prospectus"> <i className="bi bi-book-half"></i> Prospectus </a>
          <ul className="dropdown-menu">
             <li><NavLink  className="dropdown-item" id="prospectus1" to="/Curriculum">Curriculum </NavLink></li>
             <li><NavLink  className="dropdown-item" id="prospectus2" to="/SchoolTimings">School Timings </NavLink></li>
             <li><NavLink  className="dropdown-item" id="prospectus2" to="/SchoolUniform"> SchoolUniform </NavLink></li>
              <li><NavLink className="dropdown-item" id="prospectus16" to="/ParentCooperation">Parent's Co-operation </NavLink></li>
             <li><NavLink  className="dropdown-item" id="prospectus4" to="/AdmissionWithdrawal">Admission &amp; Withdrawal </NavLink></li>
              <li><NavLink className="dropdown-item" id="prospectus5" to="/AdmissionRules">Admission Rules </NavLink></li>
             <li><NavLink  className="dropdown-item" id="prospectus6" to="/ProcessAdmission">Process of Admission   </NavLink></li>
             <li><NavLink  className="dropdown-item" id="prospectus7" to="/FeeRules">Fee Rules & Structure </NavLink></li>
             <li><NavLink  className="dropdown-item" id="prospectus8" to="/WithdrawalDismissal">Withdrawal &amp; Dismissal Rules </NavLink></li>
              <li><NavLink className="dropdown-item" id="prospectus9" to="/AssessmentRules">Assessment Rules </NavLink></li>
              <li><NavLink className="dropdown-item" id="prospectus10" to="/LibraryRules">Library Rules </NavLink></li>
              <li><NavLink className="dropdown-item" id="prospectus11" to="/Attendance">Attendance Rules </NavLink></li>
              <li><NavLink className="dropdown-item" id="prospectus12" to="/Tc">Transfer Certificate </NavLink></li>
              <li><NavLink className="dropdown-item" id="prospectus13" to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Declaration.pdf" target="_blank">Declaration  </NavLink></li>
              <li><NavLink className="dropdown-item" id="prospectus14" to="/BookList">Book Lists </NavLink></li>
          </ul>
        </li>
        <li className="nav-item dropdown"> <div className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown" id="message"> <i className="bi bi-vector-pen"></i> Message </div>
        <ul className="dropdown-menu">
            <li><NavLink className="dropdown-item" id="messages1" to="/PresidentMessage">President  </NavLink></li>
          
            <li><NavLink className="dropdown-item" id="messages3" to="/ManagerMessage">Manager </NavLink></li>
            <li><NavLink className="dropdown-item" id="messages4" to="/PrincipalMessage">Principal  </NavLink></li>
            <li><NavLink className="dropdown-item" id="messages9" to="/SeniorWingCoordinatorMessage">Coordinator (Senior Wing)  </NavLink></li>
            <li><NavLink className="dropdown-item" id="messages11" to="/SupervisorMessage">Supervisor (Senior Wing)  </NavLink></li>
            <li><NavLink className="dropdown-item" id="messages10" to="/JuniorWingCoordinatorMessage">Coordinator (Junior Wing)  </NavLink></li>
          
        </ul>
      </li>
        <li className="nav-item dropdown"> <div className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown"> <i className="bi bi-card-checklist"></i> Bulletin </div>
          <ul className="dropdown-menu">
             <li><NavLink  className="dropdown-item" to="/Highlights">Notice Board </NavLink></li>
             <li><NavLink  className="dropdown-item" to=" ">Announcements </NavLink></li>
              <li><div className="dropdown-item" to="#">Seminars, Webinars, Conferences  &raquo; </div>
              <ul className="submenu dropdown-menu">
                 <li><NavLink  className="dropdown-item" to="/Teachers">Teachers  </NavLink></li>
                 <li><NavLink  className="dropdown-item" to="/Students"> Students  </NavLink></li>
              </ul>
            </li>
             <li><NavLink  className="dropdown-item" to="/Magazine">Magazines </NavLink></li>
              <li><div className="dropdown-item" to="#"> Gallery &raquo; </div>
              <ul className="submenu dropdown-menu">
                 <li><NavLink  className="dropdown-item" id="gallery1" to="/PhotoGallery">Photo Gallery </NavLink></li>
                  <li><NavLink className="dropdown-item" id="gallery2" to="/VideoGallery">Video Gallery </NavLink></li>
                   <li><NavLink className="dropdown-item" id="gallery2" to="/ClassroomTeaching">Classroom Teaching </NavLink></li>
                  <li><NavLink className="dropdown-item" id="gallery3" to="/Memories">Memories </NavLink></li>
                 <li><NavLink  className="dropdown-item" id="gallery4" to="/MediaGallery">Media Gallery </NavLink></li>
                 <li><NavLink  className="dropdown-item" id="gallery5" to="/VoiceSms">Voice SMS </NavLink></li>
                 <li><NavLink  className="dropdown-item" id="gallery6" to="/VirtualTour">Virtual Tour </NavLink></li>
              </ul>
            </li>
          </ul>
        </li>
        <li className="nav-item dropdown"> <div className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown"> <i className="bi bi-joystick"></i> Activities
          
          </div>
          <ul className="dropdown-menu">
              <li><div className="dropdown-item" to="#"> NCC &raquo; </div>
              <ul className="submenu dropdown-menu">
                 <li><NavLink  className="dropdown-item" id="ncc" to="/NccArmy">NCC (Army) </NavLink></li>
                  <li><NavLink className="dropdown-item" id="ncc2" to="/NccNavy">NCC (NAVY) </NavLink></li>
              </ul>
            </li>
             <li><NavLink  className="dropdown-item" to="/News">Upshot of Activities  </NavLink></li>
             <li><NavLink  className="dropdown-item" to="/SocialOutreach">Social Outreach </NavLink></li>
          </ul>
        </li>
        <li className="nav-item dropdown"> <a className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown"> <i className="bi bi-people"></i> Office Bearers </a>
          <ul className="dropdown-menu">
             <li><NavLink  className="dropdown-item" id="office1" to="/ManagementCommittee">Management Committee </NavLink></li>
             <li><NavLink  className="dropdown-item" id="office1" to="/Committee"> Committee </NavLink></li>
             <li><NavLink  className="dropdown-item" id="office4" to="/SchoolParliament">School Parliament </NavLink></li>
            
          </ul>
        </li>
        <li className="nav-item dropdown"> <a className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown"> <i className="bi bi-building"></i> Events
          
          </a>
          <ul className="dropdown-menu">
              <li><div className="dropdown-item" to="#">SXMUN &raquo; </div>
              <ul className="submenu dropdown-menu">
                 <li><NavLink  className="dropdown-item" id="A1" to="/SxmunInformation">Information </NavLink></li>
              </ul>
            </li>
             <li><NavLink  className="dropdown-item" to="/luminaire">Luminaire </NavLink></li>
          </ul>
        </li>
        <li className="nav-item dropdown"> <a className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown"> <i className="bi bi-journal-text"></i> CBSE Disclosure </a>
          <ul className="dropdown-menu">
             <li><NavLink  className="dropdown-item" to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Mandatory-Disclosure-Xavier-Bathinda.pdf" target="_blank">Mandatory Public Disclosure </NavLink></li>
              <li><div className="dropdown-item" to="#"> Documents & Information &raquo; </div>
              <ul className="submenu dropdown-menu submenu_right">
                 <li><NavLink  className="dropdown-item" to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Affiliation.pdf" target="_blank">Affiliation </NavLink></li>
                 <li><NavLink  className="dropdown-item" to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Registration-Society.pdf" target="_blank">Registration of Society </NavLink></li>
                 <li><NavLink  className="dropdown-item" to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/noc.pdf" target="_blank">No Obejction Certificate </NavLink></li>
                 <li><NavLink  className="dropdown-item" to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Fire-Certificate.pdf" target="_blank">Fire Safety Certificate </NavLink></li>
                 <li><NavLink  className="dropdown-item" to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Water-Sanitation.pdf" target="_blank">Water, Health & Sanitation </NavLink></li>
                 <li><NavLink  className="dropdown-item" to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Valid-Building-Certificate.pdf" target="_blank">Valid Building Safety Certificate </NavLink></li>
              </ul>
            </li>
              <li><div className="dropdown-item" to="#"> Result & Academics &raquo; </div>
              <ul className="submenu dropdown-menu submenu_right">
                 <li><NavLink  className="dropdown-item" to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Fee-Structure-school.pdf" target="_blank">Fee Structure of the school </NavLink></li>
                 <li><NavLink  className="dropdown-item" to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Annual-Academic-Calender.pdf" target="_blank">Annual Academic Calendar </NavLink></li>
                 <li><NavLink  className="dropdown-item" to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Last-3-year-result.pdf" target="_blank">Last 3 Year Result </NavLink></li>
                 <li><NavLink  className="dropdown-item" to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/School-Management-Committee.pdf" target="_blank">School Management Committee </NavLink></li>
              </ul>
            </li>
             <li><NavLink  className="dropdown-item" to="/faculty">Staff ( Teaching ) </NavLink></li>
              <li><div className="dropdown-item" to="#"> School Infrastructure &raquo; </div>
              <ul className="submenu dropdown-menu submenu_right">
                 <li><NavLink  className="dropdown-item" id="A2" to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/No-of-Labs.pdf" target="_blank">No. & Size of Labs </NavLink></li>
              </ul>
            </li>
             <li><NavLink  className="dropdown-item" target="_blank" to="https://www.youtube.com/watch?v=B9nhHlweWd0">School Video </NavLink></li>
          </ul>
        </li>
        <li className=""> <NavLink className="nav-link" to="/Career"><i className="bi bi-briefcase"></i> Career  </NavLink></li>
      </ul>
    </div>
    
  </div>
  
</nav>
</div>
     </>
  )
}

export default Header
