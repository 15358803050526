import React from 'react' 

const TeachingStaff = () => {
   return (
    <>
<tr><td>1</td><td>Fr. Cidloy Furtado</td><td>Principal</td><td>M.A, B.Ed</td></tr>
<tr><td>2</td><td>Mrs. Archana Rajput</td><td>Coordinator</td><td>M.A, B.Ed</td></tr>
<tr><td>3</td><td>Mrs. Pallavi</td><td>Coordinator</td><td>M.A, B.Ed</td></tr>
<tr><td>4</td><td>Mrs. Vineet Dua</td><td>Counsellor</td><td>M.A in Psychology</td></tr>
{/* <tr><td>5</td><td>Mrs. Ashu Beniwal</td><td>Counsellor</td><td>M.A (Psychology)</td></tr> */}
<tr><td>5</td><td>Ms. Nupur</td><td>Supervisor</td><td>M.Com, B.Ed</td></tr>
<tr><td>6</td><td>Mr. Vijay Bhudev</td><td>Teacher</td><td>B.A, Dip. in Fine Art, Art &amp; Craft</td></tr>
<tr><td>7</td><td>Mr. Jagjit Singh Sidhu</td><td>Teacher</td><td>E.C.Engg, B.Sc, IT</td></tr>
{/* <tr><td>9</td><td>Mrs. Bhavna Aggarwal</td><td>Teacher</td><td>B.A, B.Ed</td></tr> */}
<tr><td>8</td><td>Mrs. Asha Garg</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>9</td><td>Mrs. Minakshi Wadhawan</td><td>Teacher</td><td>B.Sc, B.Ed</td></tr>
<tr><td>10</td><td>Mrs. Ashima Sadhu</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
{/* <tr><td>13</td><td>Mr. Sarbjeet Singh</td><td>Teacher</td><td>M.A, B.Ed</td></tr> */}
<tr><td>11</td><td>Mrs. Sonia Jindal</td><td>Teacher</td><td>B.Sc, B.Ed, P.G D.P in Dietitics</td></tr>
<tr><td>12</td><td>Mr. Devinder Pal Singh</td><td>Teacher</td><td>M.A, B.P.Ed</td></tr>
<tr><td>13</td><td>Mrs. Prabhjot Kaur</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>14</td><td>Mr. Vikramjeet Singh Sidhu</td><td>Teacher</td><td>M.Sc, B.Ed</td></tr>
<tr><td>15</td><td>Mr. Rajat Taneja</td><td>Teacher</td><td>B.Sc, B.Ed</td></tr>
<tr><td>16</td><td>Mrs. Rajwinder Kaur</td><td>Teacher</td><td>M.A, M.Ed</td></tr>
<tr><td>17</td><td>Mrs. Gurvinder Kaur</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>18</td><td>Mrs. Monika Verma</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>19</td><td>Ms. Rose</td><td>Teacher</td><td>B.A Hons in Bharatnatyam</td></tr>
<tr><td>20</td><td>Mrs. Seema Arora</td><td>Teacher</td><td>M.Sc, B.Ed</td></tr>
<tr><td>21</td><td>Ms. Amandeep Kaur Dhaliwal</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>22</td><td>Mrs. Neha Sharma</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>23</td><td>Mrs. Pallavi Garg</td><td>Teacher</td><td>M.Sc, B.Ed</td></tr>
<tr><td>24</td><td>Mr. Paramjeet Singh Khurmi</td><td>Teacher</td><td>M.Sc, M.Phil, B.Ed</td></tr>
<tr><td>25</td><td>Mrs. Poonam Baghla</td><td>Teacher</td><td>M.Com, B.Ed</td></tr>
<tr><td>26</td><td>Mrs. Sunita Goyal</td><td>Teacher</td><td>M.Sc, B.Ed</td></tr>
<tr><td>27</td><td>Mrs. Punam Sharma</td><td>Teacher</td><td>B.Sc, M.A, B.Ed</td></tr>
<tr><td>28</td><td>Ms. Jaspal Kaur Sidhu</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>29</td><td>Mrs. Rajwinder Kaur Brar</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>30</td><td>Mrs. Pushpinder Kaur</td><td>Teacher</td><td>M.Sc, PGDCA, NT.T, B.Ed</td></tr>
<tr><td>31</td><td>Mrs. Shilpa Jaidka</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>32</td><td>Mrs. Sunita Mittal</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>33</td><td>Mrs. Saiqua Jaffar</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
{/* <tr><td>37</td><td>Mrs. Monica Vinocha</td><td>Teacher</td><td>M.A, M.Phil, B.Ed</td></tr> */}
<tr><td>34</td><td>Mrs. Saroj Sharma</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>35</td><td>Mrs. Shivani Gupta</td><td>Teacher</td><td>B.Sc, B.Ed</td></tr>
<tr><td>36</td><td>Mrs. Rimmi Bhardwaj</td><td>Teacher</td><td>M.Sc, M.A, B.Ed, N.T.T</td></tr>
<tr><td>37</td><td>Mrs. Geeta Gupta</td><td>Teacher</td><td>B.A, N.T.T</td></tr>
<tr><td>38</td><td>Mrs. Arwinder Kaur</td><td>Teacher</td><td>M.Com, B.Ed</td></tr>
<tr><td>39</td><td>Mrs. Sarita Mittal</td><td>Teacher</td><td>M.Com, M.CA, B.Ed</td></tr>
<tr><td>40</td><td>Mrs. Manu Radha Rani</td><td>Teacher</td><td>M.Com, B.Ed</td></tr>
<tr><td>41</td><td>Mrs. Meenakshi Aggarwal</td><td>Teacher</td><td>BA, B.Ed</td></tr>
<tr><td>42</td><td>Mrs. Bharti Sharma</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>43</td><td>Mrs. Deepali Sharma</td><td>Teacher</td><td>M.Sc, B.Ed</td></tr>
<tr><td>44</td><td>Mrs. Shikha Bansal</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>45</td><td>Mrs. Babli Gill</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>46</td><td>Mrs. Archna Gupta</td><td>Teacher</td><td>M.Sc, B.Ed</td></tr>
<tr><td>47</td><td>Mrs. Maninder Jit Kaur</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>48</td><td>Mrs. Ravneet Kaur Smagh</td><td>Teacher</td><td>M.Sc, B.Ed</td></tr>
<tr><td>49</td><td>Mrs. Nidhi Aryan</td><td>Teacher</td><td>M.Sc, B.Ed</td></tr>
<tr><td>50</td><td>Ms. Amandeep Kaur</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>51</td><td>Mrs. Mandeep Kaur</td><td>Teacher</td><td>Dip. Art &amp; Craft, B.A, N.T.T</td></tr>
<tr><td>52</td><td>Mrs. Ramjeet Kaur</td><td>Teacher</td><td>M.Phil, M.Ed</td></tr>
<tr><td>53</td><td>Mrs. Reema Sharma</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>54</td><td>Mrs. Davinder Preet Kaur Nagpal</td><td>Teacher</td><td>B.A, N.T.T</td></tr>
<tr><td>55</td><td>Mrs. Mamta Yadav</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>56</td><td>Mrs. Diljot Kaur</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>57</td><td>Mrs. Vibha Bansal</td><td>Teacher</td><td>M.Sc, B.Ed</td></tr>
<tr><td>58</td><td>Mrs. Gagandeep Kaur Sra</td><td>Teacher</td><td>M.A, M.Ed</td></tr>
<tr><td>59</td><td>Mrs. Namita Dass</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>60</td><td>Mrs. Rashmi</td><td>Teacher</td><td>MCA, Three yrs Poly Tech. Dip in IT</td></tr>
<tr><td>61</td><td>Mrs. Simarjeet Kaur</td><td>Teacher</td><td>M.A, M.Ed</td></tr>
<tr><td>62</td><td>Mrs. Gurjeet Kaur</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>63</td><td>Mrs. Amandeep Kaur</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>64</td><td>Mrs. Archana Gupta</td><td>Teacher</td><td>M.Sc, B.Ed</td></tr>
<tr><td>65</td><td>Mrs. Inderpreet Brar</td><td>Teacher</td><td>M.J.M.C, BBA</td></tr>
<tr><td>66</td><td>Mrs. Rajdeep Kaur Sidhu</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>67</td><td>Mrs. Ramandeep Kaur Sandhu</td><td>Teacher</td><td>M.A, M.Phil, B.Ed</td></tr>
<tr><td>68</td><td>Mrs. Ritu Verma</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>69</td><td>Mrs. Ritu Bassi</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
{/* <tr><td>74</td><td>Mrs. Jasmeen Kaur</td><td>Teacher</td><td>M.A, B.Ed</td></tr> */}
<tr><td>70</td><td>Mrs. Rajbeer Kaur</td><td>Teacher</td><td>M.A, B.Ed, M.Phil</td></tr>
<tr><td>71</td><td>Mrs. Anita Rana</td><td>Teacher</td><td>M.A, N.T.T</td></tr>
<tr><td>72</td><td>Ms. Manjit kaur</td><td>Teacher</td><td>B.A</td></tr>
<tr><td>73</td><td>Mrs. Reena Rani</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>74</td><td>Mrs. Navreet Kaur</td><td>Teacher</td><td>M.Tech</td></tr> 
<tr><td>76</td><td>Mrs. Chandna Jain</td><td>Teacher</td><td>M.Phil, B.Ed</td></tr>
{/* <tr><td>82</td><td>Ms. Shruti Nohria</td><td>Teacher</td><td>M.B.A</td></tr> */}
<tr><td>77</td><td>Mrs. Vandana Shukla</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>78</td><td>Mrs. Sukhvinder Kaur</td><td>Assistant Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>79</td><td>Mrs. Savina Rani</td><td>Teacher</td><td>M.A,B.Ed</td></tr>
<tr><td>80</td><td>Mrs. Baljeet Kaur Mahal</td><td>Teacher</td><td>B.A,M.A,B.Ed</td></tr>
<tr><td>81</td><td>Mrs. Rupinder Kaur</td><td>Teacher</td><td>B.A,M.A,B.Ed</td></tr>
<tr><td>82</td><td>Mrs. Divya Jain</td><td>Teacher</td><td>B.Com, PGDCA, M.sc, MCA, B.Ed</td></tr>
{/* <tr><td>89</td><td>Mrs. Saumya Mishra</td><td>Teacher</td><td>BBA, PGDM, D.Ed</td></tr> */}
<tr><td>83</td><td>Mr. Rahul Agnihotri</td><td>Teacher</td><td>B.com, B.Ed</td></tr>
<tr><td>84</td><td>Mrs. Liza Mukhi</td><td>Teacher</td><td>B.sc, M.sc</td></tr> 
<tr><td>85</td><td>Mr. Anuj Vats</td><td>Teacher</td><td>Diploma in Pracheen Kala Kendra</td></tr>
<tr><td>86</td><td>Ms. Arshdeep Kaur Brar</td><td>Teacher</td><td>B.A, M.A, B.Ed, M.Ed</td></tr>
<tr><td>87</td><td>Mrs. Arshpreet Kaur</td><td>Teacher</td><td>M.A, B.Ed</td></tr>
<tr><td>88</td><td>Mr. Vinod Kumar Choonbook</td><td>Teacher</td><td>B.CA</td></tr>
<tr><td>89</td><td>Mr. Varinder Singh</td><td>Teacher</td><td>B.P.E.D, M.P.E.D</td></tr>
<tr><td>90</td><td>Mrs. Zainab El Ayssaoui</td><td>Teacher</td><td>Advanced Accelerated  French</td></tr>
<tr><td>91</td><td>Mrs. Chahat</td><td>Teacher</td><td>B.com, M.com, B.Ed</td></tr>
<tr><td>92</td><td>Ms. Vipneet Kaur</td><td>Teacher</td><td>M.Com, B.Ed</td></tr>
<tr><td>93</td><td>Mrs. Madhavi Das</td><td>Teacher</td><td>PGDCA, PGDM, M.Ed</td></tr>
<tr><td>94</td><td>Mrs. Puneet Jindal</td><td>Teacher</td><td>N.T.T, B.A</td></tr>
<tr><td>95</td><td>Mrs. Satwinder Kaur</td><td>Teacher</td><td>M.Com, M.B.A</td></tr>
{/* <tr><td>104</td><td>Mrs. Shikha Sharma</td><td>Teacher</td><td>M.Com, B.Ed</td></tr> */}
<tr><td>96</td><td>Ms. Jyoti Nehra</td><td>Teacher</td><td>M.A, MBA</td></tr>
<tr><td>97</td><td>Ms. Lovina Goyal</td><td>Teacher</td><td>M.Sc, B.Ed</td></tr>
{/* <tr><td>107</td><td>Mrs. Kainaz Khairabadi</td><td>Teacher</td><td>M.Com, B.Ed</td></tr> */}
<tr><td>98</td><td>Mrs. Anjali Vijay Katkar</td><td>Teacher</td><td>M.Sc, B.Ed</td></tr>
<tr><td>99</td><td>Mr. Ajay Kumar</td><td>Teacher</td><td>M.A Music Vocal</td></tr>
<tr><td>100</td><td>Ms. Rosanne Mystica Pereira</td><td>Teacher</td><td>B.Ed</td></tr>
<tr><td>101</td><td>Mr. Gurjeet Singh</td><td>Teacher</td><td>M.A</td></tr>
<tr><td>102</td><td>Mrs. Jashandeep Kalra</td><td>Teacher</td><td>M.A, M.Ed</td></tr>
<tr><td>103</td><td>Mrs. Harnita Azad</td><td>Teacher</td><td>B.Com, M.B.A</td></tr>
<tr> <td>104</td> <td>Mr. Bindesar</td> <td>Teacher</td> <td>B.P.E.D, M.P.E.D</td> </tr>  
<tr> <td>105</td> <td>Mrs. Akansha Tyagi</td> <td>Teacher</td> <td>B.A, B.Ed</td> </tr> 
<tr> <td>106</td> <td>Mrs. Sandeep Kaur Sandhu</td> <td>Teacher</td> <td>M.sc, B.Ed</td> </tr> 
<tr> <td>107</td> <td>Mrs. Anshu Singla</td> <td>Teacher</td> <td>M.A, B.Ed</td> </tr> 
<tr> <td>108</td> <td>Mrs. Babbal Preet Kaur</td> <td>Teacher</td> <td>M.A, B.Ed</td> </tr> 
<tr> <td>109</td> <td>Ms. Khwaish Rana</td> <td>Teacher</td> <td>B.A, M.A</td> </tr> 
<tr> <td>110</td> <td>Mrs. Kusum Taneja</td> <td>Teacher</td> <td>M.A, B.Ed</td> </tr> 
<tr> <td>111</td> <td>Mrs. Nitika</td> <td>Teacher</td> <td>M.CA, B.Ed, B.CA</td> </tr> 
<tr> <td>112</td> <td>Mrs. Tanu Gupta</td> <td>Teacher</td> <td>M.Com, B.Ed</td> </tr> 
<tr> <td>113</td> <td>Mrs. Priyanka Galhotra</td> <td>Teacher</td> <td>M.Tech, B.Tech, B.Ed</td> </tr> 
<tr> <td>114</td> <td>Ms. Cuty Yadav</td> <td>Teacher</td> <td>B.P.E, Certified Coach in Fitness</td> </tr> 
<tr> <td>115</td> <td>Mr. Vikram</td> <td>Teacher</td> <td>M.A (Theatre & Television)</td> </tr>
<tr><td>116 </td> <td>Mrs. Bhupinder Kaur </td> <td> 	Librarian/ Teacher </td> <td>	B.A, B.Ed, M.L.I.S </td> </tr> 
<tr><td>117 </td> <td>Mr. Surinder Singh </td> <td> 	Smart Class Coordinator/ Teacher </td> <td>	B.sc IT </td> </tr> 
<tr><td>118 </td> <td>Mr. Shahid Maqsood </td> <td> 	Smart Class Coordinator/ Teacher </td> <td>	B.A, PGDCA </td> </tr> 
<tr><td>119 </td> <td>Mr. Parbhakar Rao </td> <td> 	Graphic Designer/ Teacher </td> <td>	Diploma in Graphic Designing </td> </tr> 
<tr><td> 120</td> <td>Ms. Jaspreet Kaur </td> <td> 	Wellness Teacher </td> <td>	GNM Diploma in Nursing </td> </tr> 
<tr><td>121 </td> <td>Mr. Prashant Barik </td> <td> 	Teacher </td> <td>	12th </td> </tr> 
<tr><td>122 </td> <td>Mrs. Meena Kumari </td> <td> 	Teacher </td> <td>	M.A, B.Ed </td> </tr> 
<tr><td>123 </td> <td>Ms. Shivani </td> <td>	Teacher </td> <td> 	B.A </td> </tr> 
<tr><td> 124</td> <td>Ms. Vishali </td><td>	Teacher	 </td> <td> B.A </td> </tr> 
<tr><td>125 </td> <td>Mrs. Arpita Debnath	 </td> <td> Teacher </td> <td>	B.A, PGD </td> </tr> 
<tr><td>126 </td> <td>Mrs. Jaswinder Kaur	 </td> <td> Teacher </td> <td>	B.Tech </td> </tr> 
<tr><td>127 </td> <td>Mrs. Gurwinder Kaur	 </td> <td> Teacher </td> <td>	BCA </td> </tr> 
<tr><td>128 </td> <td>Mrs. Simmi Khosla	 </td> <td> Teacher </td> <td>	M.Sc, M.Ed </td> </tr> 
<tr><td>129 </td> <td>Ms. Monika Rani	 </td> <td> Teacher </td> <td>	M.sc, PGDCA </td> </tr> 
<tr><td>130 </td> <td>Mr. Satya Parkash </td> <td> 	Teacher	</td> <td> B.Tech </td> </tr> 
<tr><td>131 </td> <td>Mrs. Akanksha Kitchlu </td> <td> Teacher	 </td>  <td>	B.Ed </td></tr>
<tr><td>132 </td> <td>Mrs. Simmi John</td> <td> Teacher	 </td>  <td>	B.Sc </td></tr>
<tr><td>133 </td> <td>Ms. Ronisha Fernandes </td> <td> Teacher	 </td>  <td>	B.A </td></tr>
<tr><td>134 </td> <td>Ms. Bambina Anita Fernandes </td> <td> Teacher	 </td>  <td>	B.B.A </td></tr>
<tr><td>135 </td> <td>Ms. Swizerl Fernandes</td> <td> Teacher	 </td>  <td>	B.Com </td></tr>
<tr><td>136 </td> <td>Mr. Gusewak Singh </td> <td> Teacher	 </td>  <td>	B.Tech </td></tr>
<tr><td>137 </td> <td>Ms. Elita Oliveira </td> <td> Teacher	 </td>  <td>	Diploma in Computer Applications </td></tr>
<tr><td>138 </td> <td>Mr. Avinash Kumar </td> <td> Teacher	 </td>  <td>	M.B.A, B.PEd </td></tr>
<tr><td>139 </td> <td>Mrs. Savita Rani</td> <td> Teacher	 </td>  <td>	B.PEd </td></tr>
<tr><td>140 </td> <td>Mr. Sanjeev Kumar </td> <td> Teacher	 </td>  <td>	B.PEd </td></tr>
<tr><td>141 </td> <td>Mr. Armeet Singh </td> <td> Teacher	 </td>  <td>	B.A </td></tr>



  </>
  )
}

export default TeachingStaff
