import React from 'react'

const NonTeachingStaff = () => {
   return (
    <>
    <tr><td>1</td><td>Mr. Clyde Romaldo Torres</td><td>Accountant</td><td>B.com</td></tr>
    <tr> <td>2</td><td>Ms. Aldrina Pereira</td><td>Accountant</td><td>B.com</td></tr>
    {/* <tr> <td>3</td><td>Mrs. Simmi John</td><td>Clerk</td><td>B.sc</td></tr> */}
    {/* <tr> <td>4</td><td>Ms. Ronisha Fernandes</td><td>Clerk</td><td>B.A</td></tr> */}
    {/* <tr> <td>5</td><td>Ms. Bambina Anita Fernandes</td><td>Clerk</td><td>B.B.A</td></tr> */}
    {/* <tr> <td>6</td><td>Ms. Swizerl Fernandes</td><td>Clerk</td><td>B.Com</td></tr> */}
    {/* <tr> <td>7</td><td>Ms. Elita Oliveira</td><td>Clerk</td><td>Diploma in Computer Application</td></tr> */}
    <tr> <td>3</td><td>Ms. Rosy Gurung</td><td>Clerk</td><td>B.A, M.A, LLB</td></tr>
    {/* <tr> <td>9</td><td>Mr. Armeet Singh</td><td>Electrician</td><td>Television Mechanic Course</td></tr> */}
    {/* <tr> <td>10</td><td>Mr. Gursewak Singh</td><td>Electrician</td><td>B.Tech</td></tr> */}
    <tr> <td>4</td><td> Ms. Sabreena Manzoor </td>  	<td> Nurse	</td>   <td>	B.sc Nursing </td></tr>

  </>
  )
}

export default NonTeachingStaff
